export const tenantIdFromSlug = (tenantSlug: string) => {
  const tenantIds = process.env.tenantIds as unknown as Record<string, string>;
  if (tenantSlug in tenantIds) {
    return tenantIds[tenantSlug];
  } else {
    return undefined;
  }
};

export const getTenantNameFromHost = (hostWithOptionalPortNumber: string) => {
  const host = hostWithOptionalPortNumber.split(":")[0];
  const domains = host.split(".").reverse();
  return domains[0].toLowerCase();
};
