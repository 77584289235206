import { Center, Heading, Link, Spinner, Stack } from "@chakra-ui/react";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import {
  getFrontendTenantConfigurationByName,
  tenantConfigurations,
} from "../shared/contexts/tenantsConfigurations";
import { getTenantNameFromHost } from "../shared/helpers/tenants";
import { publicAssetUrl } from "../shared/helpers/urls";

export default function Custom404() {
  const router = useRouter();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const tenantConfig = getTenantConfigFromWindow();
    if (tenantConfig) {
      window.location.replace(tenantConfig.links.homePage);
    } else {
      setLoading(false);
    }
  }, [router]);

  if (loading) {
    return (
      <Center height="100vh">
        <Spinner />
      </Center>
    );
  }

  return (
    <>
      <Head>
        <title>Page Not Found</title>
        <link rel="icon" href={publicAssetUrl("/favicon_CH-DE.svg")} />
      </Head>
      <Center>
        <Stack alignItems="center" mt="40px">
          <Heading>Page Not Found</Heading>
          <Link href="https://www.onlinedoctor.de">www.onlinedoctor.de</Link>
          <Link href="https://www.onlinedoctor.ch">www.onlinedoctor.ch</Link>
        </Stack>
      </Center>
    </>
  );
}

const getTenantConfigFromWindow = () => {
  let tenantConfig = getFrontendTenantConfigurationByName(
    getTenantNameFromHost(window.location.hostname)
  );

  if (tenantConfig) {
    return tenantConfig;
  }

  for (const config of tenantConfigurations) {
    if (window.location.pathname.includes(`/t/${config.name}/`)) {
      return config;
    }
  }

  return undefined;
};
